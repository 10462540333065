<template>
  <phan-quyen-bao-cao-linh-vuc />
</template>

<script>
import PhanQuyenBaoCaoLinhVuc from '@/modules/mot-cua/components/pages/bao-cao-thong-ke/PhanQuyenBaoCaoLinhVuc.vue'

export default {
  name: 'TrangChu',
  components: {
    PhanQuyenBaoCaoLinhVuc,
  },
}
</script>

<style scoped></style>
