<template>
  <div>
    <div v-if="formMode === 'Add'">
      <b-form-group
        id="input-group-1"
        label="Tên báo cáo"
        label-for="input-1"
        class="required"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenBaoCao"
          :rules="{ required: true }"
          name="tenBaoCao"
        >
          <treeselect
            id="linhVuc"
            ref="tenBaoCao"
            v-model="dataForm.baoCaoId"
            v-format-v-select
            :default-options="npCbx"
            placeholder="Chọn báo cáo"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            :load-options="onChange"
            :async="true"
            :clear-on-select="true"
            @select="$refs.tenBaoCao.resetSearchQuery()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
    </div>
    <div v-else-if="formMode === 'Edit'">
      <b-form-group
        id="input-group-1"
        label="Tên báo cáo"
        label-for="input-1"
        class="required"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenBaoCao"
          :rules="{ required: true }"
          name="tenBaoCao"
        >
          <treeselect
            id="linhVuc"
            ref="tenBaoCao"
            v-model="dataForm.baoCaoId"
            v-format-v-select
            :options="bcCbx"
            placeholder="Chọn tên báo cáo"
            :clear-on-select="true"
            :limit="1"
            :disabled="true"
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            :normalizer="normalizer"
          />
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
    </div>
    <b-form-group
      id="input-group-1"
      label="Tên lĩnh vực"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenLinhVuc"
        :rules="{ required: true }"
        name="tenLinhVuc"
      >
        <treeselect
          id="linhVuc"
          ref="tenLinhVuc"
          v-model="dataForm.linhVucId"
          v-format-v-select
          :default-options="lvCbx"
          placeholder="Chọn lĩnh vực"
          :limit="0"
          multiple
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          loading-text="Đang tìm kiếm"
          :normalizer="normalizer"
          :match-keys="['label', 'label2']"
          :load-options="onQueryChange"
          :async="true"
          :clear-on-select="true"
          @select="$refs.tenLinhVuc.resetSearchQuery()"
        >
          <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName }"
            :class="labelClassName"
            :title="node.label"
          >
            {{ node.label }}
          </label>
        </treeselect>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import { BFormGroup } from 'bootstrap-vue'
import { removeDiacritical } from '@/utils/index'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import _debounce from 'lodash/debounce'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormGroup,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
    formMode: { type: String, default: 'Add' },
  },
  data() {
    return {
      lvCbx: [],
      npCbx: [],
      bcCbx: [],
    }
  },
  mounted() {
    this.getDataLinhVucCbx()
    this.getDataBaoCaoLinhVucCbx()
    this.getDataBaoCaoCbx()
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.lvCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchChange: _debounce(async function (query, callback) {
      const response = this.npCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    getDataLinhVucCbx() {
      this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.lvCbx = res.data.data
        }
      })
    },
    getDataBaoCaoLinhVucCbx() {
      this.$axios
        .get(END_POINTS.BAO_CAO_LINH_VUC.DS_BAO_CAO_CHUA_CAU_HINH)
        .then(res => {
          if (res.data?.succeeded) {
            this.npCbx = res.data.data
          }
        })
    },
    getDataBaoCaoCbx() {
      this.$axios.get(END_POINTS.BAO_CAO.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.bcCbx = res.data.data
        }
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .vs__selected {
  background-color: #005bd4 !important;
}
::v-deep .vs__deselect:disabled svg.feather.feather-x{
  display: none !important;
}
</style>
